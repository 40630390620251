// Fonts
@import url('https://fonts.googleapis.com/css?family=Questrial');

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

// Variables
@import 'variables';
@import 'backgrounds';
@import 'texts';
@import 'links';
@import 'sidebars-bootstrap';


.text-ccdl-light-blue {
    color: $ccdl-light-blue!important;
}

.text-ccdl-dark-blue {
    color: $ccdl-dark-blue!important;
}

.text-ccdl-red {
    color: $ccdl-red!important;
}

.text-ccdl-yellow {
    color: $ccdl-yellow!important;
}

.text-ccdl-whatsapp {
    color: $ccdl-whatsapp!important;
}

.text-navbar-ccdl-white {
    color: #dddddd;
    &:hover {
        color: white
    }
}

.link-ccdl-yellow {
    color: white!important;
    &:hover {
        color: $ccdl-yellow!important;
    }

}

.link-ccdl-facebook {
    color: white!important;
    &:hover {
        color: $ccdl-facebook!important;
    }
}

.link-ccdl-instagram {
    color: white!important;
    &:hover {
        color: $ccdl-instagram!important;
    }
}

.link-ccdl-twitter {
    color: white!important;
    &:hover {
        color: $ccdl-twitter!important;
    }
}
